import { FC } from 'react'

import type {
  FindQueuerDashboardQuery,
  FindQueuerDashboardQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import QueuerDashboardMainSection from 'src/components/QueuerDashboard/QueuerDashboardMainSection'
import type { TabId } from 'src/pages/QueuerDashboardPage/QueuerDashboardPage'

export const beforeQuery = (props: unknown) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindQueuerDashboardQuery {
    # Get the QueuerJobType enum defined in the DB schema
    getQueuerJobTypes

    getQueuedJobs {
      jobs {
        id
        name
        data {
          jobType
        }
        returnvalue {
          message
        }
        attemptsMade
        failedReason

        progressPercent
        delayDurationMinutes
        nextRunDate
        createdDate
        finishedDate
        processedDate
        queue {
          name
        }
        clientName
      }

      repeatableJobs {
        id
        key
        name
        pattern
        endDate
        nextDate
        queue {
          name
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export type GetQueuedJobs = SuccessProps['getQueuedJobs']

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type SuccessProps = CellSuccessProps<
  FindQueuerDashboardQuery,
  FindQueuerDashboardQueryVariables
> & {
  tabId?: TabId
}

export const Success: FC<SuccessProps> = ({
  getQueuerJobTypes,
  getQueuedJobs,
  queryResult: { refetch },
  ...props
}) => {
  return (
    <QueuerDashboardMainSection
      queuerJobTypes={getQueuerJobTypes}
      queuedJobs={getQueuedJobs}
      refetch={refetch}
      {...props}
    />
  )
}
