import React, { FC, useMemo, useState } from 'react'

import { TextField } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { BRISBANE_TZ } from 'api/src/common/constants'
import cronstrue from 'cronstrue'
import dayjs from 'dayjs'
import { rrulestr } from 'rrule'
import { QueuedJobList } from 'types/graphql'

interface QueuerProps {
  queuedJobs: QueuedJobList
}

const QueuerDashboardRecurringJobsSection: FC<QueuerProps> = ({
  queuedJobs,
}) => {
  const [searchString, setSearchString] = useState<string>('')

  const filteredJobs = useMemo(() => {
    if (!queuedJobs.repeatableJobs) {
      return []
    }

    const filtered = queuedJobs.repeatableJobs.filter((job) => {
      return job.name.toLowerCase().includes(searchString.toLowerCase())
    })

    return filtered
  }, [queuedJobs.repeatableJobs, searchString])

  return (
    <div className="m-4 rounded-md bg-white p-4 shadow">
      <TextField
        id="search"
        size={'small'}
        label="Search Job"
        variant="outlined"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        fullWidth
        className="mb-4"
        data-testid="recurring-jobs-search-job"
      />

      <div>
        <DataGrid
          autoHeight
          rows={filteredJobs}
          columns={columnRepeatableJobsDef}
          pageSizeOptions={[10, 20]}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          getRowId={(row) => row.name}
          data-testid={`${filteredJobs.length > 0 ? 'recurring-jobs-table-filled' : 'recurring-jobs-table-empty'}`}
        />
      </div>
    </div>
  )
}

const columnRepeatableJobsDef: GridColDef<
  QueuedJobList['repeatableJobs'][0]
>[] = [
  {
    field: 'job',
    headerName: 'Job',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (_value, row) => {
      return row?.name
    },
  },
  {
    field: 'nextDate',
    headerName: 'Next Run Time (+10:00)',
    sortable: false,
    filterable: false,
    headerAlign: 'center', // Center aligns the column header
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      const date = dayjs(params.row?.nextDate).tz(BRISBANE_TZ)
      const formattedDate = date.format('D-MMM-YY, HH:mm')

      return (
        <span
          title={date.toISOString()}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          {formattedDate}
        </span>
      )
    },
  },

  {
    field: 'endDate',
    headerName: 'Last Run Time',
    sortable: false,
    filterable: false,
    headerAlign: 'center', // Center aligns the column header
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      const getLastRunDate = () => {
        const endDate = params.row?.endDate
        if (
          !endDate ||
          endDate === '1970-01-01T00:00:00.000Z' ||
          !dayjs(endDate).isValid()
        ) {
          return null
        }
        return dayjs(endDate)
      }
      const lastRunDate = getLastRunDate()
      const formattedDate = lastRunDate?.format('D-MMM-YY, HH:mm')

      return (
        <span
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          {formattedDate ?? 'Never'}
        </span>
      )
    },
  },
  {
    field: 'pattern',
    headerName: 'Repeat Pattern',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    headerAlign: 'center', // Center aligns the column header
    flex: 1,
    renderCell: (params) => {
      const isRRule = params.row?.pattern.includes('RRULE')

      return (
        <div
          style={{ textAlign: 'center', width: '100%' }}
          title={params.row?.pattern}
        >
          {isRRule ? 'RRule...' : params.row?.pattern}
        </div>
      )
    },
  },
  {
    field: 'readablePattern',
    headerName: 'Readable Pattern',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    headerAlign: 'center', // Center aligns the column header
    flex: 1,
    renderCell: (params) => {
      let humanReadable: string
      let nextTimes: string[] = null

      const isRRule = params.row?.pattern.includes('RRULE')
      if (isRRule) {
        humanReadable = 'Next times...'
        const rule = rrulestr(params.row?.pattern)
        nextTimes = rule
          .all((_, i) => i < 10)
          .map((date) => dayjs.tz(date, BRISBANE_TZ).format())
      } else {
        try {
          humanReadable = cronstrue.toString(params.row?.pattern)
        } catch (e) {
          // Do nothing
        }
      }

      return (
        <div
          style={{ textAlign: 'center', width: '100%' }}
          title={nextTimes?.join('\n')}
        >
          {humanReadable}
        </div>
      )
    },
  },
]

export default QueuerDashboardRecurringJobsSection
