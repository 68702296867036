import React from 'react'

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { getBrisbaneTime } from 'api/src/common/utils'
import dayjs from 'dayjs'
import { range } from 'ramda'
import { JOB_TYPE, QueuedJobList } from 'types/graphql'

interface FormProps {
  queuerJobTypes: JOB_TYPE[]
  queuedJobs: QueuedJobList
}

const QueuerDashboardDataSourceStatusSection: React.FC<FormProps> = ({
  queuerJobTypes,
  queuedJobs,
}) => {
  const getHoursOfDay = () => range(0, 24)

  const getDaysOfMonth = () => {
    const daysInMonth = dayjs().endOf('month').date()
    const days = range(1, daysInMonth + 1)
    return days
  }

  const queuerJobs = Object.values(queuerJobTypes).map((jobType) => ({
    id: jobType,
    name: jobType,
  }))

  const aggregateAttempts = (jobType: JOB_TYPE, timeUnit: string) => {
    const currentDate = new Date()
    const currentDay = currentDate.getDate()
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()
    const currentHour = currentDate.getHours()

    const timePeriods = timeUnit === 'hour' ? getHoursOfDay() : getDaysOfMonth()

    const attemptsColor = timePeriods.map((period) => {
      const maxAttemptsInPeriod = queuedJobs.jobs.reduce((maxAttempts, job) => {
        if (job.data.jobType === jobType) {
          const jobDate = new Date(job.createdDate)
          const isSameTimePeriod =
            timeUnit === 'hour'
              ? jobDate.getHours() === period &&
                jobDate.getDate() === currentDay &&
                jobDate.getMonth() === currentMonth &&
                jobDate.getFullYear() === currentYear
              : jobDate.getDate() === period &&
                jobDate.getMonth() === currentMonth &&
                jobDate.getFullYear() === currentYear

          if (isSameTimePeriod) {
            return Math.max(maxAttempts, job.attemptsMade)
          }
        }
        return maxAttempts
      }, 0)

      if (maxAttemptsInPeriod === 0) {
        return period < (timeUnit === 'hour' ? currentHour : currentDay)
          ? 'black'
          : 'grey'
      } else if (maxAttemptsInPeriod > 5) {
        return 'red'
      } else if (maxAttemptsInPeriod > 1) {
        return 'orange'
      } else {
        return 'green'
      }
    })

    return attemptsColor
  }

  const columnRepeatableJobsDef: GridColDef<{
    id: JOB_TYPE
    name: JOB_TYPE
  }>[] = [
    {
      field: 'job',
      headerName: 'Job',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      valueGetter: (_value, row) => row?.name,
    },
    {
      field: 'hourlyAttempts',
      headerName: 'Status for Current Day',
      sortable: false,
      headerAlign: 'center', // Center aligns the column header

      filterable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const attemptsColors = aggregateAttempts(params.row.name, 'hour')
        return (
          <div style={{ display: 'flex', width: '100%', height: '20px' }}>
            {attemptsColors.map((color, index) => (
              <div
                key={index}
                title={`${index}:00`}
                style={{
                  flex: 1,
                  backgroundColor: color,
                  marginLeft: index === 0 ? 0 : '2px', // Add padding between bars
                }}
              />
            ))}
          </div>
        )
      },
    },
    {
      field: 'dailyAttempts',
      headerName: 'Status for Current Month',
      headerAlign: 'center', // Center aligns the column header
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const attemptsColors = aggregateAttempts(params.row.name, 'day')
        return (
          <div style={{ display: 'flex', width: '100%', height: '20px' }}>
            {attemptsColors.map((color, index) => (
              <div
                key={index}
                title={`${index + 1} ${getBrisbaneTime().format('MMM')}`}
                style={{
                  flex: 1,
                  backgroundColor: color,
                  marginLeft: index === 0 ? 0 : '2px', // Add padding between bars
                }}
              />
            ))}
          </div>
        )
      },
    },
  ]

  return (
    <div className="bg-white rounded-md shadow m-4 p-4">
      <DataGrid<{
        id: JOB_TYPE
        name: JOB_TYPE
      }>
        sx={{
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          zIndex: 0,
        }}
        autoHeight
        rows={queuerJobs}
        columns={columnRepeatableJobsDef}
        pageSizeOptions={[10, 20]}
        initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
        getRowId={(row) => row.id}
      />
    </div>
  )
}

export default QueuerDashboardDataSourceStatusSection
