import React, { FC } from 'react'

import { PowerIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { Box, Chip, Grid2 as Grid, Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import tinycolor from 'tinycolor2'

import { setStringToTitleCase } from 'src/components/Automations/utils'
import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import Button from 'src/components/Library/Button/Button'
import { QueuerTaskSwitchboardSwitch } from 'src/components/QueuerDashboard/QueuerDashboardSwitchboardCell/QueuerDashboardSwitchboardCell'
import { getFormattedDate } from 'src/components/QueuerDashboard/utils'

interface QueuerDashboardSwitchboardSwitchRowProps {
  handleResetSwitch: (switchId: number) => void
  handleStartJob: (switchId: number) => void
  startingJob: boolean
  switches: QueuerTaskSwitchboardSwitch[]
  handleTogglePower: (switchId: number) => void
  alertOnly: boolean
  filterJobName: string
}

const QueuerDashboardSwitchboardSwitchRow: FC<
  QueuerDashboardSwitchboardSwitchRowProps
> = ({
  handleResetSwitch,
  handleStartJob,
  startingJob,
  switches,
  handleTogglePower,
  alertOnly,
  filterJobName,
}) => {
  return (
    <Stack>
      {/* Column Headings */}
      <Grid container className={'px-2 py-2'} spacing={2}>
        <Grid size={{ xs: 1, sm: 1 }}>
          <Typography align={'center'} variant="h6"></Typography>
        </Grid>
        <Grid size={{ xs: 5, sm: 5 }}>
          <Typography variant="h6">Job Name</Typography>
        </Grid>
        <Grid size={{ xs: 1, sm: 1 }}>
          <Typography align={'center'} variant="h6">
            Last Started
          </Typography>
        </Grid>
        <Grid size={{ xs: 1, sm: 1 }}>
          <Typography align={'center'} variant="h6">
            Last Completed
          </Typography>
        </Grid>
        <Grid size={{ xs: 1, sm: 1 }}>
          <Typography align={'center'} variant="h6">
            Live Until
          </Typography>
        </Grid>
        <Grid size={{ xs: 1, sm: 1 }}>
          <Typography align={'center'} variant="h6">
            Last Proc.
          </Typography>
        </Grid>
        <Grid size={{ xs: 1, sm: 1 }}>
          <Typography align={'center'} variant="h6">
            Total Proc.
          </Typography>
        </Grid>

        <Grid size={{ xs: 1, sm: 1 }}>
          <Typography align={'center'} variant="h6">
            State
          </Typography>
        </Grid>
      </Grid>

      {/* Render Integration Resources */}
      {switches
        .filter((switchItem) => {
          if (alertOnly) {
            return switchItem.isAlert
          }
          return true
        })
        .filter((switchItem) => {
          if (filterJobName) {
            const jobName = switchItem.queuerJobType.toLowerCase()
            const searchTerms = filterJobName.toLowerCase().split(' ') // Split filterJobName into words

            // Check if all search terms are found in the jobName
            return searchTerms.every((term) => jobName.includes(term))
          }
          return true
        })
        .map((switchItem) => {
          let state = ''
          let color = ''
          if (!switchItem.active) {
            state = 'OFF'
            color = getHexForColor('gray')
          } else if (switchItem.isAlert) {
            state = 'ALERT'
            color = getHexForColor('red')
          } else if (switchItem.isIncomplete) {
            state = 'INCOMPLETE'
            color = getHexForColor('blue')
          } else {
            state = 'COMPLETE'
            color = getHexForColor('green')
          }

          return (
            <Grid
              container
              key={switchItem.id}
              className={'border-b px-2 py-2'}
              spacing={2}
              alignItems={'center'}
            >
              <Grid size={{ xs: 1, sm: 1 }}>
                <Box className={'flex justify-center'}>
                  <Stack
                    direction="row"
                    spacing={0}
                    className={'flex justify-end'}
                    alignItems={'center'}
                  >
                    <Tooltip title={`Turn ${switchItem.active ? 'Off' : 'On'}`}>
                      <div>
                        <Button
                          fullWidth={false}
                          variant="text"
                          className="min-w-[0] rounded-full p-2"
                          loading={startingJob}
                          onClick={() => {
                            handleTogglePower(switchItem.id)
                          }}
                        >
                          <PowerIcon
                            className={`h-6 w-6 rounded-lg ${
                              switchItem.active
                                ? 'text-green-600'
                                : 'text-red-600'
                            }`}
                          />
                        </Button>
                      </div>
                    </Tooltip>
                    <Tooltip title={'Reset Switch'}>
                      <div>
                        <Button
                          fullWidth={false}
                          variant="text"
                          className="min-w-[0] rounded-full p-2"
                          onClick={() => {
                            handleResetSwitch(switchItem.id)
                          }}
                        >
                          <ArrowPathIcon className="h-6 w-6 text-indigo-600" />
                        </Button>
                      </div>
                    </Tooltip>
                    <Tooltip title={'Trigger Job'}>
                      <div>
                        <Button
                          fullWidth={false}
                          variant="text"
                          className="min-w-[0] rounded-full p-2"
                          disabled={!switchItem.active}
                          onClick={() => {
                            handleStartJob(switchItem.id)
                          }}
                        >
                          <RocketLaunchIcon
                            className={`h-6 w-6 ${
                              switchItem.active
                                ? 'text-indigo-600'
                                : 'text-gray-600'
                            }`}
                          />
                        </Button>
                      </div>
                    </Tooltip>
                  </Stack>
                </Box>
              </Grid>
              <Grid size={{ xs: 5, sm: 5 }}>
                <Typography>
                  {`${setStringToTitleCase(switchItem.queuerJobType)}${
                    switchItem.integration?.integrationName
                      ? ' - ' + switchItem.integration.integrationName
                      : ''
                  }`}
                </Typography>
              </Grid>
              <Grid size={{ xs: 1, sm: 1 }}>
                <Typography align={'center'}>
                  {getFormattedDate(switchItem.lastStartTime)}
                </Typography>
              </Grid>
              <Grid size={{ xs: 1, sm: 1 }}>
                <Typography align={'center'}>
                  {getFormattedDate(switchItem.lastCompletedTime)}
                </Typography>
              </Grid>
              <Grid size={{ xs: 1, sm: 1 }}>
                <Typography align={'center'}>
                  {getFormattedDate(
                    switchItem.fetchFromTime ?? switchItem.lastCompletedTime,
                  )}
                </Typography>
              </Grid>
              <Grid size={{ xs: 1, sm: 1 }}>
                <Typography align={'center'}>
                  {switchItem.iterationProcessed}
                </Typography>
              </Grid>
              <Grid size={{ xs: 1, sm: 1 }}>
                <Typography align={'center'}>
                  {switchItem.totalProcessed}
                </Typography>
              </Grid>

              <Grid size={{ xs: 1, sm: 1 }}>
                <Box className={'flex justify-center'}>
                  <Chip
                    size={'small'}
                    label={state}
                    style={{
                      backgroundColor: color,
                      color: tinycolor(color).darken(40).toString(),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          )
        })}
    </Stack>
  )
}

export default QueuerDashboardSwitchboardSwitchRow
