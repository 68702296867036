import React, { FC, useEffect, useMemo, useState } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { BRISBANE_TZ } from 'api/src/common/constants'
import { getBrisbaneTime } from 'api/src/common/utils'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
} from 'chart.js'
import dayjs from 'dayjs'
import { sortWith } from 'ramda'
import { Bar, Pie } from 'react-chartjs-2'
import {
  deleteQueuerJob,
  deleteQueuerJobVariables,
  QueuedJob,
  QueuedJobList,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { LoaderIcon, toast } from '@redwoodjs/web/toast'

import { setStringToTitleCase } from 'src/components/Automations/utils'
import Button from 'src/components/Library/Button/Button'
import { getFormattedDate } from 'src/components/QueuerDashboard/utils'

const DELETE_QUEUER_JOB = gql`
  mutation deleteQueuerJob($jobId: String!, $queueName: String!) {
    deleteQueuerJob(jobId: $jobId, queueName: $queueName)
  }
`

interface QueuerProps {
  queuedJobs: QueuedJobList
}

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
)

const QueuerDashboardQueuedJobsSection: FC<QueuerProps> = ({ queuedJobs }) => {
  const [searchString, setSearchString] = useState<string>('')
  const [deleteJobIdLoading, setDeleteJobIdLoading] = useState<string>(null)
  const [chartData, setChartData] = useState({ datasets: [], labels: [] })
  const [successFailureChartData, setSuccessFailureChartData] = useState({
    datasets: [],
    labels: [],
  })
  const [attemptsChartData, setAttemptsChartData] = useState({
    datasets: [],
    labels: [],
  })

  const pieChartColors = [
    'rgba(75, 192, 192, 1)', // green
    'rgba(54, 162, 235, 1)', // blue
    'rgba(255, 206, 86, 1)', // yellow
    'rgba(255, 99, 132, 1)', // red
    'rgba(153, 102, 255, 1)', // purple
    'rgba(255, 159, 64, 1)', // orange
    'rgba(233, 30, 99, 1)', // pink
    'rgba(33, 150, 243, 1)', // light blue
    'rgba(205, 220, 57, 1)', // lime
    'rgba(96, 125, 139, 1)', // blue grey
  ]

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
      },
      x: {
        stacked: true,
      },
    },
  }
  const columnQueuedJobsDef: GridColDef<QueuedJob>[] = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      flex: 1,
      valueGetter: (_value, row) => {
        return row?.name
      },
    },
    {
      field: 'job',
      headerName: 'Job',
      sortable: false,
      filterable: true,
      disableColumnMenu: false,
      flex: 1,
      valueGetter: (_value, row) => {
        return setStringToTitleCase(row?.data?.jobType)
      },
    },
    {
      field: 'clientName',
      headerName: 'Client',
      sortable: false,
      filterable: true,
      disableColumnMenu: false,
      flex: 1,
      valueGetter: (_value, row) => {
        return setStringToTitleCase(row?.clientName)
      },
    },
    {
      field: 'queueName',
      headerName: 'Queue',
      sortable: false,
      filterable: true,
      disableColumnMenu: false,
      flex: 1,
      valueGetter: (_value, row) => {
        return setStringToTitleCase(row?.queue.name.split('_')[0])
      },
    },

    {
      field: 'processedDate',
      headerName: 'Started',
      sortable: true,
      filterable: false,
      disableColumnMenu: false,
      headerAlign: 'center', // Center aligns the column header
      flex: 1,
      renderCell: (params) => {
        const processedDate = params.row?.processedDate
          ? dayjs(params.row?.processedDate).tz(BRISBANE_TZ)
          : null
        const formattedDate = processedDate
          ? getFormattedDate(processedDate.toISOString())
          : 'N/A'

        return (
          <span
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {params.row?.processedDate ? formattedDate : 'Queued'}
          </span>
        )
      },
    },
    {
      field: 'finishedDate',
      headerName: 'Completed',
      sortable: true,
      filterable: false,
      disableColumnMenu: false,
      headerAlign: 'center', // Center aligns the column header
      flex: 1,
      renderCell: (params) => {
        const date = params.row?.finishedDate
          ? dayjs(params.row?.finishedDate).tz(BRISBANE_TZ)
          : null
        const formattedDate = date
          ? getFormattedDate(date.toISOString())
          : 'N/A'
        return (
          <span
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {params.row?.finishedDate ? formattedDate : ''}
          </span>
        )
      },
    },
    {
      field: 'timeTaken',
      headerName: 'Time Taken',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: 'center', // Center aligns the column header
      flex: 1,
      renderCell: (params) => {
        const processedOn = new Date(params.row?.processedDate)
        const finishedOn = new Date(params.row?.finishedDate)
        const timeTaken = finishedOn.getTime() - processedOn.getTime()
        const formattedDate = humanReadableTime(timeTaken / 1000)
        return (
          <span
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {params.row?.finishedDate ? formattedDate : ''}
          </span>
        )
      },
    },
    {
      field: 'progress',
      headerName: 'Progress',
      description: 'Progress %',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: 'center', // Center aligns the column header
      flex: 1,
      renderCell: (params) => {
        const progress = params.row?.progressPercent
        const completed = !!params.row?.finishedDate
        return (
          <span
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            title="Progress %"
          >
            {completed ? '100%' : `${progress}%`}
          </span>
        )
      },
    },
    {
      field: 'attempts',
      headerName: 'Attempts',
      sortable: true,
      filterable: false,
      headerAlign: 'center', // Center aligns the column header
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {params.row?.attemptsMade > 0 ? params.row?.attemptsMade : ''}
          </span>
        )
      },
    },
    {
      field: 'failedReason',
      headerName: 'Errors',
      sortable: true,
      filterable: false,
      headerAlign: 'center', // Center aligns the column header
      disableColumnMenu: true,
      flex: 1,
      valueGetter: (_value, row) => {
        return row?.failedReason
      },
    },
    {
      field: 'delay',
      headerName: 'Delay',
      sortable: true,
      filterable: false,
      headerAlign: 'center', // Center aligns the column header
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        const hasDelay = params.row?.delayDurationMinutes !== 0
        if (!hasDelay) {
          return false
        }

        const nextRunDate = dayjs(params.row?.createdDate)
          .add(params.row?.delayDurationMinutes, 'minute')
          .tz(BRISBANE_TZ)

        const delayInMillis = nextRunDate.diff(dayjs())

        return (
          <Tooltip
            title={
              <>
                {nextRunDate.format('D-MMM-YY, HH:mm Z')}
                <br />
                {dayjs.utc(params.row?.nextRunDate).format('D-MMM-YY, HH:mm Z')}
              </>
            }
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {hasDelay
                ? dayjs.duration(delayInMillis).humanize()
                : 'Immediate'}
            </span>
          </Tooltip>
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      headerAlign: 'center', // Center aligns the column header
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        const isDeleteLoading = deleteJobIdLoading === params.row.id
        return (
          <Stack
            direction="row"
            spacing={2}
            className="flex w-full items-center justify-center"
          >
            <Tooltip title={'Abort/Delete Job'}>
              <div>
                <Button
                  fullWidth={false}
                  loading={isDeleteLoading}
                  variant="outlined"
                  className="min-w-[0] rounded-full p-2"
                  onClick={() =>
                    handleDeleteJob(params.row.id, params.row.queue.name)
                  }
                >
                  {isDeleteLoading ? (
                    <LoaderIcon />
                  ) : (
                    <TrashIcon className="h-5 w-5 text-red-600" />
                  )}{' '}
                </Button>
              </div>
            </Tooltip>
          </Stack>
        )
      },
    },
  ]

  const handleDeleteJob = async (jobId: string, queueName: string) => {
    const payload = {
      queueName: queueName,
      jobId: jobId,
    }

    setDeleteJobIdLoading(jobId)
    await DeleteQueuerJob({
      variables: payload,
    }).finally(() => {
      setDeleteJobIdLoading(null)
    })
  }

  const [DeleteQueuerJob] = useMutation<
    deleteQueuerJob,
    deleteQueuerJobVariables
  >(DELETE_QUEUER_JOB, {
    onCompleted: ({ deleteQueuerJob: status }) => {
      toast.success(`Success: Job ${status}`)
    },
    refetchQueries: ['FindQueuerDashboardQuery'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const processData = () => {
    const jobCounts = queuedJobs.jobs.reduce((acc, job) => {
      const date = getBrisbaneTime(job.createdDate)
      // Format the date key to include the date and hour
      const dateKey = date.format('YYYY-MM-DD HH:00')
      if (!acc[dateKey]) {
        acc[dateKey] = { success: 0, failure: 0 }
      }
      if (job.failedReason) {
        acc[dateKey].failure++
      } else if (job.finishedDate) {
        acc[dateKey].success++
      }

      return acc
    }, {})

    const labels = Object.keys(jobCounts).sort((a, b) => (a > b ? 1 : -1))
    const successData = labels.map((label) => jobCounts[label].success)
    const failureData = labels.map((label) => jobCounts[label].failure)

    return { labels, successData, failureData }
  }

  const processDataForAttemptsPieChart = () => {
    const attemptsCount = queuedJobs.jobs.reduce((acc, job) => {
      const attempts = job.attemptsMade
      if (attempts !== null && attempts !== 0) {
        acc[attempts] = (acc[attempts] || 0) + 1
      }
      return acc
    }, {})

    const labels = Object.keys(attemptsCount)
    const data = Object.values(attemptsCount)

    return {
      labels: labels.map(
        (label) => `${label} attempt${label !== '1' ? 's' : ''}`,
      ),
      datasets: [
        {
          data,
          backgroundColor: labels.map((label, index) => {
            // If the number of attempts is more than 10, use red, else use pieChartColors
            return parseInt(label) > 10
              ? 'rgba(255, 99, 132, 1)'
              : pieChartColors[index % pieChartColors.length]
          }),
        },
      ],
    }
  }

  const processDataForPieCharts = () => {
    const { successCount, failureCount, totalAttempts } =
      queuedJobs.jobs.reduce(
        (acc, job) => {
          if (job.failedReason) {
            acc.failureCount++
          } else if (job.finishedDate) {
            acc.successCount++
          }
          acc.totalAttempts += job.attemptsMade
          return acc
        },
        { successCount: 0, failureCount: 0, totalAttempts: 0 },
      )

    const successFailureData = {
      labels: ['Success', 'Failure'],
      datasets: [
        {
          data: [successCount, failureCount],
          backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
        },
      ],
    }

    const attemptsData = {
      labels: ['Total Attempts'],
      datasets: [
        {
          data: [totalAttempts],
          backgroundColor: ['rgba(54, 162, 235, 1)'],
        },
      ],
    }

    return { successFailureData, attemptsData }
  }

  const humanReadableTime = (seconds: number) => {
    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''}`
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60)
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600)
      return `${hours} hour${hours !== 1 ? 's' : ''}`
    } else {
      const days = Math.floor(seconds / 86400)
      return `${days} day${days !== 1 ? 's' : ''}`
    }
  }

  useEffect(() => {
    const { labels, successData, failureData } = processData()
    setChartData({
      labels,
      datasets: [
        {
          label: 'Success',
          data: successData,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
        {
          label: 'Failure',
          data: failureData,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    })

    const { successFailureData } = processDataForPieCharts()
    setSuccessFailureChartData(successFailureData)
    const newAttemptsChartData = processDataForAttemptsPieChart()
    setAttemptsChartData(newAttemptsChartData)
  }, [queuedJobs])

  const jobsRows = useMemo(() => {
    return sortWith(
      [
        (a, b) => {
          if (a.processedDate && b.processedDate) {
            // Completed in DESC order
            return dayjs(b.processedDate).diff(dayjs(a.processedDate))
          } else if (a.processedDate) {
            return -1
          } else if (b.processedDate) {
            return 1
          } else if (a.nextRunDate && b.nextRunDate) {
            // Delay in ASC order
            return dayjs(b.nextRunDate).diff(dayjs(a.nextRunDate)) * -1
          }
          return 0
        },
      ],
      queuedJobs.jobs.filter((job) => {
        return (
          job.name?.toLowerCase()?.includes(searchString.toLowerCase()) ||
          job.clientName?.toLowerCase()?.includes(searchString.toLowerCase())
        )
      }),
    )
  }, [queuedJobs.jobs, searchString])

  return (
    <>
      <div className="m-4 rounded-md bg-white p-4 shadow">
        <TextField
          id="search"
          size={'small'}
          label="Search Job Or Client"
          variant="outlined"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          fullWidth
          className="mb-4"
          data-testid="queued-jobs-search-job-or-client"
        />

        <DataGrid<QueuedJob>
          sx={{
            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            zIndex: 0,
          }}
          autoHeight
          rows={jobsRows}
          columns={columnQueuedJobsDef}
          pageSizeOptions={[10, 20, 30]}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          getRowId={(row) => row.id}
          data-testid={`${jobsRows.length > 0 ? 'current-jobs-table-filled' : 'current-jobs-table-empty'}`}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-[3fr_1fr_1fr]">
        <div className="w-full rounded border bg-white p-6">
          <div className="h-full w-full">
            <Bar data={chartData} options={options} />
          </div>
        </div>
        <div className="flex items-center justify-center rounded border bg-white p-6">
          <div className="flex h-64 w-full items-center justify-center">
            <Pie data={successFailureChartData} />
          </div>
        </div>

        <div className="flex items-center justify-center rounded border bg-white p-6">
          <div className="flex h-64 w-full items-center justify-center">
            <Pie data={attemptsChartData} />
          </div>
        </div>
      </div>
    </>
  )
}

export default QueuerDashboardQueuedJobsSection
