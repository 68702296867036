import type {
  addQueuerJob,
  addQueuerJobVariables,
  resetQueuerTaskSwitchboardItemVariables,
  ClearBullMQJobs,
  ClearBullMQJobsVariables,
  StartJobSync,
  StartJobSyncVariables,
  toggleQueuerTaskSwitchboardItem,
  toggleQueuerTaskSwitchboardItemVariables,
  resetQueuerTaskSwitchboardItem,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

export const CLEAR_BULLMQ_JOBS = gql`
  mutation ClearBullMQJobs {
    clearBullMQJobs {
      redisKeysDeleted
    }
  }
`

export const START_JOB_SYNC = gql`
  mutation StartJobSync {
    startJobSync {
      id
    }
  }
`

export const CREATE_QUEUER_JOB = gql`
  mutation addQueuerJob(
    $jobName: String!
    $jobType: JOB_TYPE!
    $clientsToStart: [ClientToStartType!]!
  ) {
    addQueuerJob(
      jobName: $jobName
      jobType: $jobType
      clientsToStart: $clientsToStart
    ) {
      id
    }
  }
`

const TOGGLE_SWITCH_POWER = gql`
  mutation toggleQueuerTaskSwitchboardItem($id: Int!, $power: Boolean!) {
    toggleQueuerTaskSwitchboardItem(id: $id, power: $power) {
      id
    }
  }
`

const RESET_SWITCH = gql`
  mutation resetQueuerTaskSwitchboardItem($id: Int!) {
    resetQueuerTaskSwitchboardItem(id: $id) {
      id
    }
  }
`

const useSwitchboardMutations = () => {
  const [clearBullMQJobs] = useMutation<
    ClearBullMQJobs,
    ClearBullMQJobsVariables
  >(CLEAR_BULLMQ_JOBS, {})

  const [startJobSync] = useMutation<StartJobSync, StartJobSyncVariables>(
    START_JOB_SYNC,
    {
      refetchQueries: ['FindQueuerSwitchboardQuery'],
      awaitRefetchQueries: false,
    },
  )

  const [toggleSwitch] = useMutation<
    toggleQueuerTaskSwitchboardItem,
    toggleQueuerTaskSwitchboardItemVariables
  >(TOGGLE_SWITCH_POWER, {
    refetchQueries: ['FindQueuerSwitchboardQuery'],
    awaitRefetchQueries: false,
  })

  const [createQueuerJob] = useMutation<addQueuerJob, addQueuerJobVariables>(
    CREATE_QUEUER_JOB,
    {
      refetchQueries: ['FindQueuerSwitchboardQuery'],
      awaitRefetchQueries: false,
    },
  )

  const [resetQueuerTaskSwitchboardItem] = useMutation<
    resetQueuerTaskSwitchboardItem,
    resetQueuerTaskSwitchboardItemVariables
  >(RESET_SWITCH, {
    refetchQueries: ['FindQueuerSwitchboardQuery'],
    awaitRefetchQueries: false,
  })

  return {
    clearBullMQJobs,
    startJobSync,
    toggleSwitch,
    createQueuerJob,
    resetQueuerTaskSwitchboardItem,
  }
}

export default useSwitchboardMutations
