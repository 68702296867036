import React, { FC, useEffect, useState } from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { SparklesIcon } from '@heroicons/react/24/solid'
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import tinycolor from 'tinycolor2'
import { IntegrationType } from 'types/graphql'
import { useDebounceValue } from 'usehooks-ts'

import { toast } from '@redwoodjs/web/toast'

import { IntegrationLogo } from 'src/components/CustomerIntegrations/IntegrationCard'
import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import Button from 'src/components/Library/Button/Button'
import {
  QueuerTaskSwitchboardClient,
  QueuerTaskSwitchboardSwitch,
} from 'src/components/QueuerDashboard/QueuerDashboardSwitchboardCell/QueuerDashboardSwitchboardCell'
import QueuerDashboardSwitchboardSwitchRow from 'src/components/QueuerDashboard/QueuerDashboardSwitchboardSwitchRow'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import Switch from '../Library/Switch/Switch'

import useSwitchboardMutations from './useSwitchboardMutations'

interface IntegrationProps {
  queuerTaskSwitchboard: QueuerTaskSwitchboardClient[]
}

const QueuerDashboardSwitchboard: FC<IntegrationProps> = ({
  queuerTaskSwitchboard,
}) => {
  const confirm = useConfirm()
  const mutations = useSwitchboardMutations()
  const [startingJob, setStartingJob] = useState(false)
  const [alertOnly, setAlertOnly] = useState(false)
  const [filterClientName, setFilterClientName] = useDebounceValue<string>(
    null,
    500,
  )
  const [filterJobName, setFilterJobName] = useDebounceValue<string>(null, 500)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isClearing, setIsClearing] = useState(false)
  const [changesMade, setChangesMade] = useState(false)
  const [filteredData, setFilteredData] = useState<
    QueuerTaskSwitchboardClient[]
  >([])

  const columnInformation: ColumnsType<QueuerTaskSwitchboardClient> = [
    {
      title: () => <Typography variant="h5">Client</Typography>, // Custom header title
      dataIndex: 'clientName',
      render: (
        clientName: string,
        record: { switches: QueuerTaskSwitchboardSwitch[] },
      ) => {
        const numVisibleJobs = record.switches
          .filter((switchItem) => {
            if (filterJobName) {
              const jobName = switchItem.queuerJobType.toLowerCase()
              const searchTerms = filterJobName.toLowerCase().split(' ') // Split filterJobName into words

              // Check if all search terms are found in the jobName
              return searchTerms.every((term) => jobName.includes(term))
            }
            return true
          })
          .filter((switchItem) => {
            if (alertOnly) {
              return switchItem.isAlert
            }
            return true
          })
        return (
          <Typography variant={'h6'}>
            {`${clientName} (${numVisibleJobs.length})`}
          </Typography>
        )
      },
    },

    {
      title: () => (
        <Stack direction={'row'} spacing={1} className={'justify-end px-3'}>
          <Typography variant="h5">Status</Typography>
        </Stack>
      ),
      render: (record: {
        isAnyRunning: boolean
        isAnyAlert: boolean
        integrationTypes: IntegrationType[]
      }) => {
        const overallState = record.isAnyAlert
          ? 'BEHIND SCHEDULE'
          : record.isAnyRunning
            ? 'SOME RUNNING'
            : 'ALL ON PAR'
        const overallColor = getHexForColor(
          record.isAnyAlert ? 'red' : record.isAnyRunning ? 'blue' : 'green',
        )
        return (
          <Stack direction={'row'} spacing={1} className={'justify-end'}>
            {record.integrationTypes.map((type, key) => (
              <Tooltip key={key} title={type}>
                <div>
                  <IntegrationLogo integrationType={type} size={'small'} />
                </div>
              </Tooltip>
            ))}
            <Divider orientation="vertical" flexItem />
            <Chip
              size={'small'}
              label={overallState}
              style={{
                backgroundColor: overallColor,
                color: tinycolor(overallColor).darken(40).toString(),
              }}
              data-testid="switchboard-status-chip"
            />
          </Stack>
        )
      },
    },
  ]

  const handleResetSwitch = async (switchId: number) => {
    try {
      await mutations.resetQueuerTaskSwitchboardItem({
        variables: {
          id: switchId,
        },
      })
      toast.success('Switch Reset Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
        className: 'flex-column',
      })
    }
  }

  const handleStartJob = async (switchId: number) => {
    setStartingJob(true)
    const clientItem = queuerTaskSwitchboard.find((client) =>
      client.switches.some((switchItem) => switchItem.id === switchId),
    )
    const switchItem = clientItem.switches.find(
      (switchItem) => switchItem.id === switchId,
    )

    try {
      await mutations.createQueuerJob({
        variables: {
          jobName: `${switchItem.queuerJobType} - ${clientItem.clientName}`,
          jobType: switchItem.queuerJobType,
          clientsToStart: [
            {
              id: clientItem.clientId,
              name: clientItem.clientName,
              switchboardSwitchId: switchId,
            },
          ],
        },
      })
      toast.success('Job Queued Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
        className: 'flex-column',
      })
    }

    setStartingJob(false)
  }

  const handleTogglePower = async (id: number) => {
    const clientItem = queuerTaskSwitchboard.find((client) =>
      client.switches.some((switchItem) => switchItem.id === id),
    )
    const switchItem = clientItem.switches.find(
      (switchItem) => switchItem.id === id,
    )

    try {
      await mutations.toggleSwitch({
        variables: {
          id: id,
          power: !switchItem.active,
        },
      })
      toast.success('Switch Toggled Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
        className: 'flex-column',
      })
    }
    setChangesMade(true)
  }

  const handleTriggerClearJobs = async () => {
    const isConfirmed = await confirm({
      title: 'Clear ALL jobs?',
      description: 'Are you sure you want to clear all jobs?',
      boolInsteadOfReject: true,
      allowClose: true,
      cancellationText: 'No',
      confirmationText: 'Yes',
    })

    if (!isConfirmed) {
      return
    }
    setIsClearing(true)
    try {
      await mutations.clearBullMQJobs()
      toast.success('BullMQ Jobs Cleared Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
        className: 'flex-column',
      })
    }
    setChangesMade(true)
    setIsClearing(false)
  }

  const handleTriggerSyncJobs = async () => {
    setIsSyncing(true)
    try {
      await mutations.startJobSync()
      toast.success('Job Queued Successfully')
    } catch (error) {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
        className: 'flex-column',
      })
    }
    setChangesMade(false)
    setIsSyncing(false)
  }

  useEffect(() => {
    // Function to filter the data based on the provided filters
    const filterData = () => {
      // Convert filter values to lowercase for case-insensitive comparison
      const lowerCaseFilterClientName = filterClientName?.toLowerCase() || ''

      // Apply the filters
      const filtered = queuerTaskSwitchboard.filter((client) => {
        // Filter by client name
        const clientNameMatches = client.clientName
          .toLowerCase()
          .includes(lowerCaseFilterClientName)

        // Filter by job name and alert status in switches
        const switchesMatch = client.switches.some((switchItem) => {
          const alertMatches = !alertOnly || switchItem.isAlert

          return alertMatches
        })

        // Include the client only if both client name and switches match the filters
        return clientNameMatches && switchesMatch
      })

      // Update the filtered data state
      setFilteredData(filtered)
    }

    // Call the filter function whenever filter values change
    filterData()
  }, [alertOnly, filterClientName, filterJobName, queuerTaskSwitchboard]) // Add dependencies
  return (
    <Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        className={'mx-4 min-w-[900px] rounded-md bg-white p-4 shadow'}
      >
        <div className="flex gap-4">
          <TextField
            label="Client Filter"
            size={'small'}
            variant="outlined"
            className="min-w-[150px]"
            onChange={(e) => {
              e.preventDefault()
              setFilterClientName(e.target.value)
            }}
            data-testid="switchboard-client-filter"
          />
          <TextField
            label="Job Filter"
            variant="outlined"
            size={'small'}
            className="min-w-[150px]"
            onChange={(e) => {
              e.preventDefault()
              setFilterJobName(e.target.value)
            }}
          />
          <FormGroup>
            <FormControlLabel
              className="w-[130px]"
              control={
                <Switch
                  checked={alertOnly}
                  onChange={() => setAlertOnly(!alertOnly)}
                />
              }
              label="Alert Only"
            />
          </FormGroup>
        </div>
        <div className="flex gap-4">
          <Button
            variant="text"
            title="Clear all BullMQ jobs"
            fullWidth={false}
            size={'small'}
            startIcon={<SparklesIcon className="h-5 w-5" />}
            className="w-[110px] min-w-[110px] rounded-lg bg-red-500 text-white hover:bg-red-600"
            loading={isClearing}
            onClick={() => handleTriggerClearJobs()}
            buttonDataTestId="clear-switch-button"
          >
            Clear Jobs
          </Button>
          <Button
            variant="text"
            fullWidth={false}
            size={'small'}
            startIcon={<SparklesIcon className="h-5 w-5" />}
            className="w-[110px] min-w-[110px] rounded-lg bg-green-500 text-white hover:bg-green-600"
            loading={isSyncing}
            onClick={() => handleTriggerSyncJobs()}
            buttonDataTestId="sync-switch-button"
          >
            Sync Jobs
          </Button>
          {changesMade && (
            <Tooltip
              title={
                <Typography variant="caption">
                  Changes made to the switchboard. <br /> Recommend clicking
                  Sync Jobs above to ensure jobs are in line with switchboard.
                </Typography>
              }
            >
              <ExclamationTriangleIcon
                className="h-7 w-7 text-yellow-600"
                aria-hidden="true"
              />
            </Tooltip>
          )}
        </div>
      </Stack>

      <Box className="m-4 min-w-[900px] rounded-md bg-white p-4 shadow">
        <div className={'relative flex w-full flex-row'}>
          <div className="w-full justify-center overflow-y-auto p-3">
            <div className={'rounded-md bg-white'}>
              <Table
                rowClassName={'audit-log-table-row-class'}
                pagination={false}
                dataSource={filteredData}
                columns={columnInformation}
                size={'small'}
                rowKey={(record) => record.clientId}
                expandable={{
                  expandedRowRender: (clientData) => (
                    <QueuerDashboardSwitchboardSwitchRow
                      handleResetSwitch={handleResetSwitch}
                      handleStartJob={handleStartJob}
                      startingJob={startingJob}
                      switches={clientData.switches}
                      filterJobName={filterJobName}
                      handleTogglePower={handleTogglePower}
                      alertOnly={alertOnly}
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    </Stack>
  )
}

export default QueuerDashboardSwitchboard
