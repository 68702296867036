import { FC } from 'react'

import {
  FindQueuerSwitchboardQuery,
  FindQueuerSwitchboardQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import QueuerDashboardSwitchboard from 'src/components/QueuerDashboard/QueuerDashboardSwitchboard'

export const beforeQuery = (props: unknown) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindQueuerSwitchboardQuery {
    # Get the QueuerJobType enum defined in the DB schema

    queuerTaskSwitchboardGroupedByClient {
      clientId
      clientName
      integrationTypes
      switches {
        id
        active
        createdAt
        updatedAt
        clientId
        queuerJobType
        iterationProcessed
        totalProcessed
        lastStartTime
        fetchFromTime
        lastCompletedTime
        integrationId
        jobTypeData {
          discardWhenJobTypeIsActive
          defaultOn
          stafflinkOnly
          requiredIntegrations {
            allOf
            oneOf
          }
        }
        isAlert
        isIncomplete
        integration {
          id
          integrationName
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  return <QueuerDashboardSwitchboard queuerTaskSwitchboard={[]} />
}
export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type SuccessProps = CellSuccessProps<
  FindQueuerSwitchboardQuery,
  FindQueuerSwitchboardQueryVariables
>

export type QueuerTaskSwitchboard =
  SuccessProps['queuerTaskSwitchboardGroupedByClient']
export type QueuerTaskSwitchboardClient =
  SuccessProps['queuerTaskSwitchboardGroupedByClient'][0]
export type QueuerTaskSwitchboardSwitch =
  SuccessProps['queuerTaskSwitchboardGroupedByClient'][0]['switches'][0]

export const Success: FC<SuccessProps> = ({
  queuerTaskSwitchboardGroupedByClient,
}) => {
  const switchboardWithSwitchesSorted =
    queuerTaskSwitchboardGroupedByClient.map((client) => {
      const sortedSwitches = [...client.switches].sort((a, b) => {
        // If both have queuerJobType, sort alphabetically
        if (a.queuerJobType && b.queuerJobType) {
          return a.queuerJobType.localeCompare(b.queuerJobType)
        }
        // If only one has queuerJobType, place it first
        if (a.queuerJobType && !b.queuerJobType) {
          return -1
        }
        if (!a.queuerJobType && b.queuerJobType) {
          return 1
        }
        // If neither has queuerJobType, keep them in the same order
        return 0
      })
      return { ...client, switches: sortedSwitches }
    })

  return (
    <QueuerDashboardSwitchboard
      queuerTaskSwitchboard={switchboardWithSwitchesSorted}
    />
  )
}
