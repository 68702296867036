import * as React from 'react'
import { FC } from 'react'

import { routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import QueuerDashboardCell from 'src/components/QueuerDashboard/QueuerDashboardCell'

export type PageQueryTypes = Partial<
  Parameters<typeof routes.queuerDashboardTab>[0]
>

export enum TabId {
  CurrentJobs = 0,
  RecurringJobs = 1,
  JobHistory = 2,
  Switchboard = 3,
}

export const tabIdSanitise = (tabId: number = TabId.CurrentJobs): TabId => {
  if (Number.isNaN(tabId) || tabId < 0 || tabId > 3) {
    throw new Error('Invalid tabId')
  }
  return tabId as TabId
}

const QueuerDashboardPage: FC<PageQueryTypes> = (props) => {
  const tabId: TabId = tabIdSanitise(props.tabId)

  return (
    <>
      <Metadata title="Queuer Dashboard" description="Queuer Dashboard" />
      <div className={'flex h-screen flex-col'}>
        <PageHeader title={'Queuer Dashboard'} />
        <div className="flex h-full flex-col overflow-y-auto">
          <QueuerDashboardCell tabId={tabId} />
        </div>
      </div>
    </>
  )
}

export default QueuerDashboardPage
